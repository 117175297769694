import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-sidebar-graph',
  templateUrl: './sidebar-graph.component.html',
  styleUrls: ['./sidebar-graph.component.scss']
})
export class SidebarGraphComponent implements OnInit {
  @Input() chart: any;

  highcharts = Highcharts;
  chartOptions;
  chartData = {};

  constructor() { }

  ngOnInit() {
    this.chartOptions = this.getGraphData(this.chart);
    this.chartOptions.series = this.getGraphDataArray()
    this.chartOptions = Object.assign(this.chartOptionsParameter(), this.chartOptions)
  }

  chartOptionsParameter() {
    return {
      tooltip: {
        formatter: function () {
          var text = 'Value: ' + this.y +
            ' Time: ' + Highcharts.dateFormat('%d/%b/%Y %I:%M %p', this.x);
          return text;
        }
      },
      title: { text: this.chart.chart_details.name },
      yAxis: { title: { text: this.chart.chart_details.sensor[0]['unit_disp'] } },
      credits: {
        enabled: false
      }
    }
  }

  getGraphData(graph) {
    var chart = eval("(" + graph.chart_details.javascript.replace(/[^\x20-\x7E]/gim, "") + ")")
    return chart
  }

  getGraphDataArray() {
    var sensorData = [];
    for (var i = 0; i < this.chart.chart_details.sensor.length; i++) {
      if (!isNullOrUndefined(this.chart.chart_details.sensor[i]['data']) && this.chart.chart_details.sensor[i]['data'].length > 0) {
        for (var j = 0; j < 1; j++) {
          sensorData.push(this.chart.chart_details.sensor[i]['data'][j]["value"]);
        }
      } else {
        sensorData.push(0);
      }
    }
    return [{ data: sensorData, name: this.chart.chart_details.name }]
  }

  convertUTCDateToLocalDate(date) {
    var newDate = new Date(date);
    newDate.setMinutes(newDate.getMinutes() - newDate.getTimezoneOffset());
    return newDate;
  }

}
