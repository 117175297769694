import { Component, Input, OnInit } from '@angular/core';
import { SensorService } from 'src/app/modules/services/sensor.service';
import * as Highcharts from "highcharts";
import { isNullOrUndefined } from "util";

@Component({
  selector: 'app-fft',
  templateUrl: './fft.component.html',
  styleUrls: ['./fft.component.scss']
})
export class FftComponent implements OnInit {
  @Input() chart: any;
  chartInst;
  highcharts = Highcharts;
  chartOptions;
  dataObj = {
    title: "",
    subtitle: "",
    yAxisTitle: ""
  };
  chartData = {};
  loadChart = false;
  dataType = 'raw';

  constructor(private sensorService: SensorService) { }

  ngOnInit() {
    console.log(this.chart);
    this.chartOptions = this.getGraphData();
    this.getSensorsData();
  }

  getSensorsData(graphType = "raw") {
    var data = {
      codes: [],
      limit: 500
    }
    if (graphType == "raw") {
      for (var i = 0; i < this.chart.chart_details.sensor.length; i++) {
        data.codes.push(this.chart.chart_details.sensor[i].uuid)
      }
    } else {
      for (var i = 0; i < this.chart.chart_details.sensor.length; i++) {
        data.codes.push(this.chart.chart_details.sensor[i].uuid + "-" + graphType)
      }
    }

    var todayDate = new Date();
    data["end_date"] = todayDate.getTime();
    this.sensorService.getSensorsData(data).subscribe((resp: any) => {
      for (var i = 0; i < this.chart.chart_details.sensor.length; i++) {
        this.chartOptions.series.push({
          uuid: graphType == "fft" || graphType == "rms-velocity" ? this.chart.chart_details.sensor[i].uuid + "-" + graphType : this.chart.chart_details.sensor[i].uuid,
          name: this.chart.chart_details.sensor[i].name,
          data: this.getGraphDataArray(graphType == "fft" || graphType == "rms-velocity" ? resp[this.chart.chart_details.sensor[i].uuid + "-" + graphType] : resp[this.chart.chart_details.sensor[i].uuid]),
          turboThreshold: 0
        });
      }
      var self = this;
      setTimeout(() => {
        self.loadChart = true;
      }, 1000);
      console.log(this.chartOptions)
    });
  }

  getGraphData() {
    for (var i = 0; i < this.chart.chart_details.sensor.length; i++) {
      if (!isNullOrUndefined(this.chart.chart_details.sensor[i]['equipment'])) {
        this.dataObj['title'] = this.chart.chart_details.sensor[i]['equipment']['name']
      } else {
        this.dataObj['title'] = this.chart.chart_details.sensor[i]['department']['name']
      }
      this.dataObj['subtitle'] = this.chart.chart_details.sensor[i]['measured_parameter_type_disp']
      this.dataObj['yAxisTitle'] = this.chart.chart_details.sensor[i]['unit_disp']
      // this.chartData[this.chart.chart_details.sensor[i].uuid] = this.getGraphDataArray(this.chart.chart_details.sensor[i]['data']);
      this.dataObj[this.chart.chart_details.sensor[i].uuid] = "this.chartData[this.chart.chart_details.sensor[" + i + "].uuid]"
    }

    var chart = eval("(" + this.chart.chart_details.javascript.replace(/[^\x20-\x7E]/gim, "") + ")")
    return chart
  }

  getGraphDataArray(data) {
    var sensorData = [];
    if (!isNullOrUndefined(data)) {
      for (var i = data.length; i--;) {
        if (data[i]["alert"]["alert_type"] != 'error_code') {
          let obj = {
            y: data[i]["value"]
          }
          sensorData.push(obj);
        }
      }
      return sensorData;
    } else {
      return [];
    }
  }

  showData(dataType) {
    this.dataType = dataType;
    this.loadChart = false;
    this.chartOptions.series = [];
    if (this.dataType == "raw") {
      this.getSensorsData()
    } else if ((this.dataType == "fft")) {
      this.getSensorsData("fft")
    } else if ((this.dataType == "rms")) {
      this.getSensorsData("rms-velocity")
    }
  }

  chartInstance(chart: Highcharts.Chart) {
    this.chartInst = chart;
  }

}
