import { Component, OnInit, ViewChild, ElementRef, Input } from "@angular/core";
import { ModalService } from "../../../services/modal.service";
import { CompanyService } from "src/app/modules/services/company.service";
import { MaterialTrackingService } from "src/app/modules/services/material-tracking.service";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { AlertService } from "src/app/modules/services/alert.service";
import { isNullOrUndefined } from "util";

@Component({
  selector: "app-create-vehicle-modal",
  templateUrl: "./create-vehicle-modal.component.html",
  styleUrls: ["./create-vehicle-modal.component.scss"]
})
export class CreateVehicleModalComponent implements OnInit {
  @ViewChild("modalButton", { static: true, read: ElementRef })
  modalButton: ElementRef<HTMLElement>;
  @ViewChild("closeButton", { static: true, read: ElementRef })
  closeButton: ElementRef<HTMLElement>;
  @Input() vehicle;
  @Input() title;
  vehicleDetailsForm: FormGroup;
  plants = [];

  constructor(
    private modalService: ModalService,
    private companyService: CompanyService,
    private materialTrackingService: MaterialTrackingService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.modalService.triggerClick(this.modalButton);
    this.getPlantDetails();

    if (!isNullOrUndefined(this.vehicle)) {
      this.vehicleDetailsForm = new FormGroup({
        plant_id: new FormControl(this.vehicle.plant.id, Validators.required),
        vehicle_no: new FormControl(this.vehicle.vehicle_no, Validators.required),
        vehicle_name: new FormControl(this.vehicle.vehicle_name, Validators.required),
        driver_name: new FormControl(this.vehicle.driver_name, Validators.required),
        driver_number: new FormControl(this.vehicle.driver_number, Validators.required),
        id: new FormControl(this.vehicle.id, Validators.required)
      });
    } else {
      this.vehicleDetailsForm = new FormGroup({
        plant_id: new FormControl("", Validators.required),
        vehicle_no: new FormControl("", Validators.required),
        vehicle_name: new FormControl("", Validators.required),
        driver_name: new FormControl("", Validators.required),
        driver_number: new FormControl("", Validators.required)
      });
    }
  }

  getPlantDetails() {
    this.companyService.getPlantDetails().subscribe((resp: any) => {
      if (resp.status) {
        this.plants = resp.data;
      }
    });
  }

  addVehicleDetails() {
    var data = this.vehicleDetailsForm.value;
    this.materialTrackingService
      .addVehicleDetails(data)
      .subscribe((resp: any) => {
        if (resp.status) {
          this.alertService.success(resp.validation);
          this.modalService.onCloseClick(data);
          this.modalService.triggerClick(this.closeButton);
        }
      });
  }
}
