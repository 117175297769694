import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from "highcharts";
import { WebsocketService } from 'src/app/modules/services/websocket.service';
import { isNullOrUndefined } from 'util';
declare var require: any
const HighchartsMore = require("highcharts/highcharts-more.src");
HighchartsMore(Highcharts);
const HC_solid_gauge = require("highcharts/modules/solid-gauge.src");
HC_solid_gauge(Highcharts);

@Component({
  selector: 'app-solid-gauge-chart',
  templateUrl: './solid-gauge-chart.component.html',
  styleUrls: ['./solid-gauge-chart.component.scss']
})
export class SolidGaugeChartComponent implements OnInit {
  @Input() chart: any;

  highcharts = Highcharts;
  chartData = {};
  updateFlag = false;

  chartOptions;
  chartInstance

  constructor(
    private websocketService: WebsocketService,
  ) { }

  ngOnInit() {
    this.chartOptions = this.getGraphData();
    var showGraphName = this.chartOptions['showGraphName'];
    this.chartOptions.series[0].data = this.getGraphDataArray()
    this.chartOptions = Object.assign(this.chartOptionsParameter(showGraphName), this.chartOptions)
    console.log(this.chartData, this.chartOptions)
    this.websocketService.sensorData.subscribe(data => {
      this.updateSocketData(data);
    });
  }

  updateSocketData(data) {
    if (Object.keys(data).length != 0) {
      if (this.chart.chart_details.sensor.length > 0 &&
        !isNullOrUndefined(data[this.chart.chart_details.sensor[0]['uuid']]) &&
        !isNullOrUndefined(this.chartInstance) &&
        !isNullOrUndefined(this.chartInstance.series) &&
        !isNullOrUndefined(this.chartInstance.series[0].points)
        ) {
        console.log("update", this.chart)
        var point = this.chartInstance.series[0].points[0];
        var val = data[this.chart.chart_details.sensor[0]['uuid']][0]["value"];
          val = val.toString().split('.');
          if (val.length > 1){
            val = parseFloat([val[0], val[1].slice(0,2)].join('.'));
          }else{
            val = parseFloat(val[0]);
          }
        point.update(val);
      }
    }
  }

  getGraphData() {
    var chart = eval("(" + this.chart.chart_details.javascript.replace(/[^\x20-\x7E]/gim, "") + ")")
    if(chart.yAxis) {
      chart.yAxis.lineWidth = 2;
      chart.yAxis.tickPositions = [chart.yAxis.min,chart.yAxis.max]
    }
    return chart
  }


  chartOptionsParameter(showGraphName) {
    return {
      title: { text: showGraphName ? this.chart.chart_details.name : this.chartData['title'] },
      credits: {
        enabled: false
      },
      backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || "#EEE",
      series: Object.assign(this.chartOptions.series[0], {
        "tooltip": {
          "valueSuffix": this.chartData['unit_disp']
        },
        "dataLabels": {
          "format": "<div style='text-align:center'><span style='font-size:16px'>{y}</span><br/><span style='font-size:10px;opacity:0.6'>" + this.chart.chart_details.sensor[0]['unit_disp'] + "</span></div>"
        }
      })
    }
  }

  getGraphDataArray() {
    var sensorData = [];
    for (var i = 0; i < this.chart.chart_details.sensor.length; i++) {
      if (!isNullOrUndefined(this.chart.chart_details.sensor[i]['data']) && this.chart.chart_details.sensor[i]['data'].length > 0) {
        if (!isNullOrUndefined(this.chart.chart_details.sensor[i]['equipment'])) {
          this.chartData['title'] = this.chart.chart_details.sensor[i]['equipment']['name']
        } else {
          this.chartData['title'] = this.chart.chart_details.sensor[i]['department']['name']
        }
        this.chartData['unit_disp'] = this.chart.chart_details.sensor[0]['unit_disp']
        for (var j = 0; j < 1; j++) {
          var val = this.chart.chart_details.sensor[i]['data'][j]["value"];
          val = val.toString().split('.');
          if (val.length > 1){
            val = parseFloat([val[0], val[1].slice(0,2)].join('.'));
          }else{
            val = parseFloat(val[0]);
          }
          sensorData.push(val);
        }
      } else {
        sensorData.push(0);
      }
    }
    console.log("============>", sensorData)
    return sensorData
  }

  chartInstanceFun(event) {
    this.chartInstance = event;
  }

}
