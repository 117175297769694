import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { SensorService } from 'src/app/modules/services/sensor.service';

@Component({
  selector: 'app-equipment-listing',
  templateUrl: './equipment-listing.component.html',
  styleUrls: ['./equipment-listing.component.scss']
})
export class EquipmentListingComponent implements OnInit {
  @Input() dept: any;
  equipments = [];
  equipment;

  constructor(
    private sensorService: SensorService
  ) {
    this.sensorService.allChartsEvent.subscribe((resp) => {
      this.equipment = null
    })
  }

  ngOnInit() {
    this.getEquipmentList(this.dept.id)
  }

  getEquipmentList(dept_id) {
    this.sensorService.getEquipmentList({ id: dept_id }).subscribe((resp: any) => {
      this.equipments = resp.data;
    })
  }

  loadEquipmentCharts(equipment) {
    this.equipment = equipment;
  }

}
