import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import Swal from "sweetalert2";

@Injectable({
  providedIn: "root"
})
export class AlertService {
  constructor(private http: HttpClient) {}

  success(msg) {
    this.alert("success", msg);
  }
  warning(msg) {
    this.alert("warning", msg);
  }
  error(msg) {
    this.alert("error", msg);
  }
  info(msg) {
    this.alert("info", msg);
  }
  question(msg) {
    this.alert("question", msg);
  }

  alert(type, msg) {
    Swal.fire({
      position: "top-end",
      icon: type,
      title: msg,
      showConfirmButton: false,
      timer: 10000,
      toast: true
    });
  }
}
