import { Component, Input, OnInit } from '@angular/core';
import { SensorService } from 'src/app/modules/services/sensor.service';
import { isNullOrUndefined } from 'util';
import * as Highcharts from "highcharts";

@Component({
  selector: 'app-kwh-trend',
  templateUrl: './kwh-trend.component.html',
  styleUrls: ['./kwh-trend.component.scss']
})
export class KwhTrendComponent implements OnInit {
  @Input() chart: any;
  chartOptions;
  loadChart = false;
  highcharts = Highcharts;
  chartInst;
  dataObj = {
    title: "",
    subtitle: "",
    yAxisTitle: ""
  };
  chartData = {}
  dataType = "hourly";
  newDate = new Date();
  startDate = this.newDate.setDate(this.newDate.getDate()-1);;
  endDate = new Date();

  constructor(private sensorService: SensorService) { }

  ngOnInit() {
    console.log(this.chart);
    this.chartOptions = this.getGraphData();
    delete this.chartOptions['tooltip']
    delete this.chartOptions['xAxis']
    delete this.chartOptions['title']
    delete this.chartOptions['yAxis']
    delete this.chartOptions['subtitle']
    var showGraphName = this.chartOptions['showGraphName'];
    delete this.chartOptions['showGraphName'];
    this.chartOptions = Object.assign(this.chartOptionsParameter(showGraphName), this.chartOptions)
    this.getSensorsData();
  }

  chartOptionsParameter(showGraphName) {
    return {
      tooltip: {
        formatter: function () {
          var text = 'Value: ' + this.y +
            ' Time: ' + Highcharts.dateFormat('%d/%b/%Y %I:%M %p', this.x);
          if (this.point.alert !== undefined && this.point.alert.status) {
            text = text + '<br>Msg: ' + this.point.alert.msg;
          }
          if (!isNullOrUndefined(this.point.parameters)){
            for (let key in this.point.parameters){
              text = text + '<br>Point: ' + key + ': '+this.point.parameters[key];
            }
          }
          return text;
        }
      },
      xAxis: {
        type: 'datetime',
        
      },
      title: { text: showGraphName ? this.chart.chart_details.name :this.dataObj['title'] },
      yAxis: { title: { text: this.dataObj['yAxisTitle'] } },
      subtitle: { text: this.dataObj['subtitle'] },
      credits: {
        enabled: false
      },
      chart: {
        zoomType: 'x'
      }
    }
  }

  getSensorsData() {
    var data = {
      start_date: new Date(this.startDate).getTime(),
      end_date: new Date(this.endDate).getTime(),
      codes: [],
      limit: 500000,
      age: this.dataType
    }
    for (var i = 0; i < this.chart.chart_details.sensor.length; i++) {
      data.codes.push(this.chart.chart_details.sensor[i].uuid)
    }
    this.sensorService.getKwhSensorsData(data).subscribe((resp: any) => {
      for (var i = 0; i < this.chart.chart_details.sensor.length; i++) {
        this.chartOptions.series.pop();
        this.chartOptions.series.push({
          uuid: this.chart.chart_details.sensor[i].uuid,
          name: this.chart.chart_details.sensor[i].name,
          data: this.getGraphDataArray(resp)
        });
      }
      var self = this;
      setTimeout(() => {
        self.loadChart = true;
      }, 500);
      console.log(this.chartOptions)
    });
  }

  getGraphData() {
    for (var i = 0; i < this.chart.chart_details.sensor.length; i++) {
      if (!isNullOrUndefined(this.chart.chart_details.sensor[i]['equipment'])) {
        this.dataObj['title'] = this.chart.chart_details.sensor[i]['equipment']['name']
      } else {
        this.dataObj['title'] = this.chart.chart_details.sensor[i]['department']['name']
      }
      this.dataObj['subtitle'] = this.chart.chart_details.sensor[i]['measured_parameter_type_disp']
      this.dataObj['yAxisTitle'] = this.chart.chart_details.sensor[i]['unit_disp']
      this.dataObj[this.chart.chart_details.sensor[i].uuid] = "this.chartData[this.chart.chart_details.sensor[" + i + "].uuid]"
    }
    var chart = eval("(" + this.chart.chart_details.javascript.replace(/[^\x20-\x7E]/gim, "") + ")")
    return chart
  }

  getGraphDataArray(data) {
    var sensorData = [];
    if (!isNullOrUndefined(data)) {
      for (var i = data.length; i--;) {
        if (data[i]["consumption"]) {
          let obj = {
            y: data[i]["consumption"],
            x: this.convertUTCDateToLocalDate(data[i]).getTime()
          }
          sensorData.push(obj);
        }
      }
      return sensorData;
    } else {
      return [];
    }
  }

  convertUTCDateToLocalDate(data) {
    var newDate = new Date(this.startDate);

    if (this.dataType == "hourly"){
      var date = data["day"]
      var newDate = new Date(date);
      newDate.setHours(newDate.getHours()+data["hour"]);
    }
    else if (this.dataType == "daily"){
      var date = data["day"]
      var newDate = new Date(date);
    }
    else if (this.dataType == "weekly"){
        var d = (1 + (data["week"] - 1) * 7); // 1st of January + 7 days for each week
        var newDate = new Date(data["year"], 0, d);
    }
    else if (this.dataType == "monthly"){
      newDate.setMonth(newDate.getMonth()+data["month"]);
    }
    newDate.setMinutes(newDate.getMinutes() - newDate.getTimezoneOffset());
    return newDate;
  }

  showData(dataType) {
    this.loadChart = false;
    this.dataType = dataType;
    this.getSensorsData();
  }

  chartInstance(chart: Highcharts.Chart) {
    this.chartInst = chart;
  }

}
