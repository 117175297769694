import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";

import { environment } from "./../../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  constructor(private http: HttpClient, private router: Router) {}
  login(username, password) {
    var data = { username: username, password: password };
    return this.http.post(`${environment.SERVER_ADDRESS}/c/login/`, data).pipe(
      map((resp: any) => {
        var userDetails = {
          username: resp.username,
          role: resp.role,
          sessionid: resp.sessionid,
          user: resp.user,
          date_joined: resp.date_joined
        };
        localStorage.setItem("user", JSON.stringify(userDetails));
        return resp;
      })
    );
  }

  logout() {
    localStorage.removeItem("user");
    return this.http.get(`${environment.SERVER_ADDRESS}/c/logout/`);
  }
}
