import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxSpinnerModule } from "ngx-spinner";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LayoutModule } from "./modules/layout/layout.module";
import { PagesModule } from "./modules/pages/pages.module";

// services
import { HttpAuthInterceptor } from "./modules/services/http.interceptor";
import { DynamicModalComponent } from "./modules/modal/dynamic-modal/dynamic-modal.component";

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    HttpClientModule,
    NgxSpinnerModule,
    NgbModule,
    AppRoutingModule,
    LayoutModule,
    PagesModule
  ],
  declarations: [AppComponent, DynamicModalComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpAuthInterceptor, multi: true }
  ],
  entryComponents: [DynamicModalComponent],
  bootstrap: [AppComponent]
})
export class AppModule {}
