import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ModalService } from "src/app/modules/services/modal.service";
import { MaterialTrackingService } from "src/app/modules/services/material-tracking.service";
import { AlertService } from "src/app/modules/services/alert.service";

@Component({
  selector: "app-trip-alerts-modal",
  templateUrl: "./trip-alerts-modal.component.html",
  styleUrls: ["./trip-alerts-modal.component.scss"]
})
export class TripAlertsModalComponent implements OnInit {
  @ViewChild("modalButton", { static: true, read: ElementRef })
  modalButton: ElementRef<HTMLElement>;
  @ViewChild("closeButton", { static: true, read: ElementRef })
  closeButton: ElementRef<HTMLElement>;

  alerts = [];

  constructor(
    private modalService: ModalService,
    private materialTrackingService: MaterialTrackingService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.modalService.triggerClick(this.modalButton);
    this.getTripAlertsDetails();
  }

  getTripAlertsDetails() {
    this.materialTrackingService
      .getTripAlertsDetails()
      .subscribe((resp: any) => {
        if (resp.status) {
          this.alerts = resp.data;
        }
      });
  }

  markTripAlertAsRead(alert) {
    this.materialTrackingService
      .markTripAlertAsRead({ id: alert.id })
      .subscribe((resp: any) => {
        if (resp.status) {
          alert.is_read = true;
          this.alertService.success(resp.validation);
        }
      });
  }
  
  onClose() {
    this.modalService.onCloseClick("");
  }
}
