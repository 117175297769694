import { Component, OnInit, ElementRef, ViewChild, Input } from "@angular/core";
import { ModalService } from "src/app/modules/services/modal.service";
import { CompanyService } from "src/app/modules/services/company.service";
import { MaterialTrackingService } from "src/app/modules/services/material-tracking.service";
import { AlertService } from "src/app/modules/services/alert.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { isNullOrUndefined } from "util";

@Component({
  selector: "app-create-device-modal",
  templateUrl: "./create-device-modal.component.html",
  styleUrls: ["./create-device-modal.component.scss"]
})
export class CreateDeviceModalComponent implements OnInit {
  @ViewChild("modalButton", { static: true, read: ElementRef })
  modalButton: ElementRef<HTMLElement>;
  @ViewChild("closeButton", { static: true, read: ElementRef })
  closeButton: ElementRef<HTMLElement>;
  @Input() device;
  @Input() title;
  deviceDetailsForm: FormGroup;
  plants = [];
  devices = [];

  constructor(
    private modalService: ModalService,
    private companyService: CompanyService,
    private materialTrackingService: MaterialTrackingService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.modalService.triggerClick(this.modalButton);
    this.getPlantDetails();
    if (!isNullOrUndefined(this.device)) {
      this.deviceDetailsForm = new FormGroup({
        plant_id: new FormControl(this.device.plant.id, Validators.required),
        uuid: new FormControl(this.device.uuid, Validators.required),
        id: new FormControl(this.device.id, Validators.required)
      });
    } else {
      this.deviceDetailsForm = new FormGroup({
        plant_id: new FormControl("", Validators.required),
        uuid: new FormControl("", Validators.required)
      });
    }
  }

  getPlantDetails() {
    this.companyService.getPlantDetails().subscribe((resp: any) => {
      if (resp.status) {
        this.plants = resp.data;
      }
    });
  }

  addDeviceDetails() {
    var data = this.deviceDetailsForm.value;
    this.materialTrackingService
      .addDeviceDetails(data)
      .subscribe((resp: any) => {
        if (resp.status) {
          this.alertService.success(resp.validation);
          this.modalService.onCloseClick(data);
          this.modalService.triggerClick(this.closeButton);
        }
      });
  }
}
