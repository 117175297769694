import {
  Component,
  OnInit,
  ComponentFactoryResolver,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import { ModalService } from "../../services/modal.service";
import { CreateTripModalComponent } from "../../pages/material/create-trip-modal/create-trip-modal.component";
import { CreateVehicleModalComponent } from "../../pages/material/create-vehicle-modal/create-vehicle-modal.component";
import { CreateDeviceModalComponent } from "../../pages/material/create-device-modal/create-device-modal.component";
import { CreateTaskModalComponent } from "../../pages/tasks/create-task-modal/create-task-modal.component";
import { TripAlertsModalComponent } from '../../pages/material/trip-alerts-modal/trip-alerts-modal.component';
import { HistoryModalComponent } from '../../pages/graph/history-modal/history-modal.component';

@Component({
  selector: "app-dynamic-modal",
  templateUrl: "./dynamic-modal.component.html",
  styleUrls: ["./dynamic-modal.component.scss"]
})
export class DynamicModalComponent implements OnInit {
  @ViewChild("container", { static: true, read: ViewContainerRef })
  entry: ViewContainerRef;

  components = {
    CreateTripModalComponent: CreateTripModalComponent,
    CreateVehicleModalComponent: CreateVehicleModalComponent,
    CreateDeviceModalComponent: CreateDeviceModalComponent,
    CreateTaskModalComponent: CreateTaskModalComponent,
    TripAlertsModalComponent: TripAlertsModalComponent,
    HistoryModalComponent: HistoryModalComponent
  };
  constructor(
    private resolver: ComponentFactoryResolver,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.modalService.modalWindowData.subscribe(data => {
      this.loadComponent(data);
    });
  }

  loadComponent(data) {
    this.entry.clear();
    const factory = this.resolver.resolveComponentFactory(
      this.components[data["component"]]
    );
    const componentRef = this.entry.createComponent(factory);
    for (let key in data["componentData"]) {
      componentRef.instance[key] = data["componentData"][key];
    }
  }
}
