import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { WebsocketService } from "../../services/websocket.service";
import { CompanyService } from "../../services/company.service";
import { ModalService } from "../../services/modal.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class FooterComponent implements OnInit {
  @ViewChild("contactsButton", { static: false, read: ElementRef })
  contactsButton: ElementRef;
  @ViewChild("scrollChatContainer", { static: false, read: ElementRef })
  scrollChatContainer: ElementRef;
  isChatBoxOpen = false;
  user;
  users = [];
  message = "";
  selectedUser;
  constructor(
    private websocketService: WebsocketService,
    private companyService: CompanyService
  ) {
    this.user = JSON.parse(localStorage.getItem("user"));
  }

  ngOnInit() {
    this.getUsersDetails();
    this.websocketService.chatData.subscribe(data => {
      this.updateSocketData(data);
    });
  }
  openChatBox() {
    this.isChatBoxOpen = !this.isChatBoxOpen;
    this.scrollToBottom();
  }

  updateSocketData(data) {
    if (!this.websocketService.isEmptyObj(data)) {
      for (let i = 0; i < this.users.length; i++) {
        if (this.users[i]["username"] == data.user.username) {
          data["align"] = "right";
          this.users[i]["chatMessages"].push(data);
          this.scrollToBottom();
        }
      }
    }
  }

  getUsersDetails() {
    this.companyService.getUsersDetails().subscribe((resp: any) => {
      if (resp.status) {
        for (let i = 0; i < resp.data.length; i++) {
          if (resp.data[i]["username"] != this.user.username) {
            this.users.push(resp.data[i]);
          }
          resp.data[i]["chatMessages"] = [];
        }
        this.selectedUser = this.users[0];
      }
    });
  }

  chatWithUser(usr) {
    this.selectedUser = usr;
    let el: HTMLElement = this.contactsButton.nativeElement;
    el.click();
    this.scrollToBottom();
  }

  sendChatData() {
    var obj = {
      user: { user: this.user.user, username: this.user.username },
      datetime: "23 Jan 2:00 pm",
      profile_pic: "assets/dist/img/user.png",
      text: this.message,
      username: this.selectedUser.username,
      align: "left"
    };
    this.selectedUser.chatMessages.push(obj);
    this.scrollToBottom();
    this.message = "";

    this.websocketService.socket.send(
      JSON.stringify({
        chat_data: obj
      })
    );
  }

  private scrollToBottom(): void {
    try {
      var el = this.scrollChatContainer.nativeElement;
      el.scroll({
        top: el.scrollHeight,
        left: 0,
        behavior: "smooth"
      });
    } catch {}
  }
}
