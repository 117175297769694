import { Component, OnInit, ViewChild, ElementRef, Input } from "@angular/core";
import { ModalService } from "src/app/modules/services/modal.service";
import { AlertService } from "src/app/modules/services/alert.service";
import { SensorService } from "src/app/modules/services/sensor.service";
import { isNullOrUndefined } from "util";

@Component({
  selector: "app-history-modal",
  templateUrl: "./history-modal.component.html",
  styleUrls: ["./history-modal.component.scss"]
})
export class HistoryModalComponent implements OnInit {
  @ViewChild("modalButton", { static: true, read: ElementRef })
  modalButton: ElementRef<HTMLElement>;
  @ViewChild("closeButton", { static: true, read: ElementRef })
  closeButton: ElementRef<HTMLElement>;
  @Input() chart: any;
  startDate;
  endDate;
  sensorId;

  constructor(
    private modalService: ModalService,
    private alertService: AlertService,
    private sensorService: SensorService
  ) {}

  ngOnInit() {
    this.modalService.triggerClick(this.modalButton);
  }

  downloadReport(reportType) {
    if (
      this.chart.chart_details.graph_type == 1 &&
      isNullOrUndefined(this.sensorId)
    ) {
      this.alertService.error("Please select sensor");
    } else if (
      reportType == "dateRange" &&
      (isNullOrUndefined(this.startDate) || isNullOrUndefined(this.endDate))
    ) {
      this.alertService.error("Please select Start date and End date");
    } else {
      var data = {
        start_date: new Date(this.startDate).getTime(),
        end_date: new Date(this.endDate).getTime(),
        code: this.chart.chart_details.sensor.uuid
      };
      if (!isNullOrUndefined(this.sensorId)) {
        data["code"] = this.sensorId;
      }
      if (reportType == "weekly") {
        var todayDate = new Date();
        data["end_date"] = todayDate.getTime();
        todayDate.setDate(todayDate.getDate() - 7);
        data["start_date"] = todayDate.getTime();
      } else if (reportType == "monthly") {
        var todayDate = new Date();
        data["end_date"] = todayDate.getTime();
        todayDate.setDate(todayDate.getDate() - 30);
        data["start_date"] = todayDate.getTime();
      } else if (reportType == "halfYearly") {
        var todayDate = new Date();
        data["end_date"] = todayDate.getTime();
        todayDate.setDate(todayDate.getDate() - 182);
        data["start_date"] = todayDate.getTime();
      } else if (reportType == "yearly") {
        var todayDate = new Date();
        data["end_date"] = todayDate.getTime();
        todayDate.setDate(todayDate.getDate() - 365);
        data["start_date"] = todayDate.getTime();
      }
      this.sensorService.downloadReport(data).subscribe((resp: any) => {
        var todayDate = new Date()
          .toString()
          .replace(/\S+\s(\S+)\s(\d+)\s(\d+)\s.*/, "$2-$1-$3");
        const anchor = document.createElement("a");
        anchor.href = "data:attachment/csv;charset=utf-8," + encodeURI(resp);
        anchor.target = "_blank";
        anchor.download =
          data["code"] + "_" + reportType + "_report_" + todayDate + ".csv";
        anchor.click();
        anchor.remove();
      });
    }
  }

  onSensorChange(event) {
    this.sensorId = event.target.value;
  }

  onClose() {
    this.modalService.onCloseClick("");
  }
}
