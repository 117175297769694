import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ModalService } from "src/app/modules/services/modal.service";
import { MaterialTrackingService } from "src/app/modules/services/material-tracking.service";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CompanyService } from 'src/app/modules/services/company.service';
import { AlertService } from 'src/app/modules/services/alert.service';

@Component({
  selector: "app-create-trip-modal",
  templateUrl: "./create-trip-modal.component.html",
  styleUrls: ["./create-trip-modal.component.scss"]
})
export class CreateTripModalComponent implements OnInit {
  @ViewChild("modalButton", { static: true, read: ElementRef })
  modalButton: ElementRef<HTMLElement>;
  @ViewChild("closeButton", { static: true, read: ElementRef })
  closeButton: ElementRef<HTMLElement>;

  tripDetailsForm: FormGroup;

  vehicles = [];
  devices = [];
  plants = [];

  constructor(
    private modalService: ModalService,
    private materialTrackingService: MaterialTrackingService,
    private companyService: CompanyService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.modalService.triggerClick(this.modalButton);
    this.getVehicleDetails();
    this.getDeviceDetails();
    this.getPlantDetails();

    this.tripDetailsForm = new FormGroup({
      plant_id: new FormControl("", Validators.required),
      devices: new FormControl("", Validators.required),
      departure: new FormControl("", Validators.required),
      arrival: new FormControl("", Validators.required),
      vehicle_id: new FormControl("", Validators.required),
    });
  }

  getPlantDetails() {
    this.companyService.getPlantDetails().subscribe((resp: any) => {
      if (resp.status) {
        this.plants = resp.data;
      }
    });
  }

  getVehicleDetails() {
    this.materialTrackingService.getVehicleDetails().subscribe((resp: any) => {
      if (resp.status) {
        this.vehicles = resp.data;
      }
    });
  }

  getDeviceDetails() {
    this.materialTrackingService.getDeviceDetails().subscribe((resp: any) => {
      if (resp.status) {
        this.devices = resp.data;
      }
    });
  }

  addTripDetails() {
    var data = this.tripDetailsForm.value;
    this.materialTrackingService
      .addTripDetails(data)
      .subscribe((resp: any) => {
        if (resp.status) {
          this.alertService.success(resp.validation);
          this.modalService.onCloseClick(data);
          this.modalService.triggerClick(this.closeButton);
        }
      });
  }
}
