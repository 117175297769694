import { Component, Input, OnInit } from '@angular/core';
import { SensorService } from 'src/app/modules/services/sensor.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {

  @Input() graph;
  table: any = {};
  tableData = {};
  showTable = false;

  constructor(private sensorService: SensorService) {}

  ngOnInit() {
    this.table = JSON.parse(this.graph.chart_details.javascript);
    this.getSensorsData();
  }

  getSensorsData() {
    var data = {
      codes: this.table.sensor_uuids,
      limit: 1
    }
    var todayDate = new Date();
    data["end_date"] = todayDate.getTime();
    this.sensorService.getSensorsData(data).subscribe((resp: any) => {
      this.tableData = resp;
      this.showTable = true;
    });

  }

}
