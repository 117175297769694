import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { map } from "rxjs/operators";
import { isNullOrUndefined } from "util";
import { Location } from '@angular/common';

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private location: Location
  ) { }

  canActivate(route: ActivatedRouteSnapshot) {
    let user = localStorage.getItem("user");
    if (!isNullOrUndefined(user) && (this.location.path() == '/login' || this.location.path() == '/' || this.location.path() == '')) {
      this.router.navigate(["/home"]);
    } else {
      return true
    }
  }
}
