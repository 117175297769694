import {
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver
} from "@angular/core";
import { GraphComponent } from "../graph/graph.component";

import { MaterialComponent } from "../material/material.component";
import { CompanyLayoutComponent } from "../graph/company-layout/company-layout.component";
import { TasksComponent } from "../tasks/tasks.component";
import { CompanyService } from "../../services/company.service";
import { ScreenLayoutComponent } from "../screen-layout/screen-layout.component";

@Component({
  selector: "app-dynamic-component",
  templateUrl: "./dynamic.component.html",
  styleUrls: ["./dynamic.component.scss"]
})
export class DynamicComponent implements OnInit {
  @ViewChild("container", { static: true, read: ViewContainerRef })
  entry: ViewContainerRef;

  components = {
    GraphComponent: GraphComponent,
    MaterialComponent: MaterialComponent,
    CompanyLayoutComponent: CompanyLayoutComponent,
    TasksComponent: TasksComponent,
    ScreenLayoutComponent:ScreenLayoutComponent
  };
  constructor(
    private resolver: ComponentFactoryResolver,
    private companyService: CompanyService
  ) {}

  ngOnInit() {
    this.companyService.dashboardComponentData.subscribe(data => {
      this.loadComponent(data);
    });
  }

  loadComponent(data) {
    this.entry.clear();
    const factory = this.resolver.resolveComponentFactory(
      this.components[data["component"]]
    );
    const componentRef = this.entry.createComponent(factory);
    for (let key in data["componentData"]) {
      componentRef.instance[key] = data["componentData"][key];
    }
  }
}
