import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { ModalService } from "src/app/modules/services/modal.service";

@Component({
  selector: "app-create-task-modal",
  templateUrl: "./create-task-modal.component.html",
  styleUrls: ["./create-task-modal.component.scss"]
})
export class CreateTaskModalComponent implements OnInit {
  @ViewChild("modalButton", { static: true, read: ElementRef })
  modalButton: ElementRef<HTMLElement>;

  constructor(private modalService: ModalService) {}

  ngOnInit() {
    this.modalService.triggerClick(this.modalButton);
  }
}
