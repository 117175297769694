import { Component, OnInit, Input } from "@angular/core";
import { SensorService } from "../../../services/sensor.service";
import * as Highcharts from "highcharts";
import { isNullOrUndefined } from "util";
import { WebsocketService } from "../../../services/websocket.service";
import { AlertService } from "../../../services/alert.service";
const noData = require('highcharts/modules/no-data-to-display')
noData(Highcharts)

@Component({
  selector: "app-chart",
  templateUrl: "./chart.component.html",
  styleUrls: ["./chart.component.scss"]
})
export class ChartComponent implements OnInit {
  @Input() chart: any;
  @Input() data: any;
  @Input() dataLimit;
  highcharts = Highcharts;
  chartInst: any;
  updateFlag = false;
  chartOptions;
  chartData = {};
  sensorsUUID = [];
  ageType = 'live';
  dataObj = {
    title: "",
    subtitle: "",
    yAxisTitle: ""
  };
  constructor(
    private websocketService: WebsocketService,
    private alertService: AlertService,
    private sensorService: SensorService
  ) {}

  ngOnInit() {
    this.chartOptions = this.getGraphData(this.chart);
    delete this.chartOptions['tooltip']
    delete this.chartOptions['xAxis']
    delete this.chartOptions['title']
    delete this.chartOptions['yAxis']
    delete this.chartOptions['subtitle']
    var showGraphName = this.chartOptions['showGraphName'];
    delete this.chartOptions['showGraphName'];
    this.chartOptions = Object.assign(this.chartOptionsParameter(showGraphName), this.chartOptions)
  
    for (var i = 0; i < this.chart.chart_details.sensor.length; i++) {
      this.sensorsUUID.push(this.chart.chart_details.sensor[i].uuid)
      this.chartOptions.series.push({ 
        uuid: this.chart.chart_details.sensor[i].uuid, 
        name: this.chart.chart_details.sensor[i].name, 
        data: this.chartData[this.chart.chart_details.sensor[i].uuid]
      });
    }

    // Update data using websocket
    this.websocketService.sensorData.subscribe(data => {
      if (this.ageType == 'live') {
        this.updateSocketData(data);
      }
    });
  }

  chartOptionsParameter(showGraphName) {
    return {
      tooltip: {
        formatter: function () {
          var text = 'Value: ' + this.y +
            ' Time: ' + Highcharts.dateFormat('%d/%b/%Y %I:%M %p', this.x);
          if (this.point.alert !== undefined && this.point.alert.status) {
            text = text + '<br>Msg: ' + this.point.alert.msg;
          }
          if (!isNullOrUndefined(this.point.parameters)){
            for (let key in this.point.parameters){
              text = text + '<br>Point: ' + key + ': '+this.point.parameters[key];
            }
          }
          return text;
        }
      },
      xAxis: {
        type: 'datetime',
        
      },
      title: { text: showGraphName ? this.chart.chart_details.name :this.dataObj['title'] },
      yAxis: { title: { text: this.dataObj['yAxisTitle'] } },
      subtitle: { text: this.dataObj['subtitle'] },
      credits: {
        enabled: false
      },
      chart: {
        zoomType: 'x'
      }
    }
  }

  getSensorDataByAgeing(ageType) {
    this.ageType = ageType;
    var data = {
      codes: this.sensorsUUID,
      limit: 100000
    }
    if (ageType == "hour") {
      var todayDate = new Date();
      data["end_date"] = todayDate.getTime();
      todayDate.setHours(todayDate.getHours() - 1);
      data["start_date"] = todayDate.getTime();
    } else if (ageType == "live") {
      var todayDate = new Date();
      data["end_date"] = todayDate.getTime();
      todayDate.setDate(todayDate.getDate() - 2);
      data["start_date"] = todayDate.getTime();
      data['limit'] = this.dataLimit;
    } else if (ageType == "day") {
      var todayDate = new Date();
      data["end_date"] = todayDate.getTime();
      todayDate.setDate(todayDate.getDate() - 1);
      data["start_date"] = todayDate.getTime();
    } else if (ageType == "week") {
      var todayDate = new Date();
      data["end_date"] = todayDate.getTime();
      todayDate.setDate(todayDate.getDate() - 7);
      data["start_date"] = todayDate.getTime();
    } else if (ageType == "month") {
      var todayDate = new Date();
      data["end_date"] = todayDate.getTime();
      todayDate.setDate(todayDate.getDate() - 30);
      data["start_date"] = todayDate.getTime();
    } else if (ageType == "halfYear") {
      var todayDate = new Date();
      data["end_date"] = todayDate.getTime();
      todayDate.setDate(todayDate.getDate() - 182);
      data["start_date"] = todayDate.getTime();
    }
    this.sensorService.getSensorsData(data).subscribe((resp: any) => {
      for (let i = 0; i < this.sensorsUUID.length; i++) {
        var key = this.sensorsUUID[i];
        for (let j = 0; j < this.chartOptions.series.length; j++) {
          if (this.chartOptions.series[j]['uuid'] == key) {
            this.chartData[key] = this.getGraphDataArray(resp[key], true)
            this.chartOptions.series[j].data = this.chartData[key];
          }
        }
      }
      this.updateFlag = true;
    });

  }

  updateSocketData(data) {
    for (let key in data) {
      for (let j = 0; j < this.chartOptions.series.length; j++) {
        if (this.chartOptions.series[j]['uuid'] == key) {
          if (this.chartData[key].length == this.dataLimit + 2) {
            this.chartData[key] = this.chartData[key].concat(
              this.getGraphDataArray(data[key], true)
            ).slice(2);
          } else {
            this.chartData[key] = this.chartData[key].concat(
              this.getGraphDataArray(data[key], true)
            )
          }
          this.chartOptions.series[j].data = this.chartData[key];
        }
      }
    }
    this.updateFlag = true;
  }

  getGraphData(graph) {
    for (var i = 0; i < this.chart.chart_details.sensor.length; i++) {
      if (!isNullOrUndefined(graph.chart_details.sensor[i]['equipment'])) {
        this.dataObj['title'] = graph.chart_details.sensor[i]['equipment']['name']
      } else {
        this.dataObj['title'] = graph.chart_details.sensor[i]['department']['name']
      }
      this.dataObj['subtitle'] = graph.chart_details.sensor[i]['measured_parameter_type_disp']
      this.dataObj['yAxisTitle'] = graph.chart_details.sensor[i]['unit_disp']
      this.chartData[graph.chart_details.sensor[i].uuid] = this.getGraphDataArray(graph.chart_details.sensor[i]['data']);
      this.dataObj[graph.chart_details.sensor[i].uuid] = "this.chartData[this.chart.chart_details.sensor[" + i + "].uuid]"
    }

    var optionString = graph.chart_details.javascript.replace(
      /\{(\w+)\}/g,
      function (_, k) {
        return this.dataObj[k];
      }
    );
    var chart = eval("(" + optionString.replace(/[^\x20-\x7E]/gim, "") + ")")
    return chart
  }

  getGraphDataArray(data, isNotify?) {
    var sensorData = [];
    if (!isNullOrUndefined(data)) {
      for (var i = data.length; i--;) {
        if (
          !isNullOrUndefined(data[i]["alert"]) &&
          data[i]["alert"]["status"] && data[i]["alert"]["alert_type"] != 'error_code'
        ) {
          var marker = {
            fillColor: "red",
            lineWidth: 3,
            lineColor: "#FF0000",
            states: {
              hover: {
                fillColor: "red",
                lineColor: "red"
              }
            }
          };
          sensorData.push({
            y: data[i]["value"],
            x: this.convertUTCDateToLocalDate(data[i]["timestamp"]).getTime(),
            marker: marker,
            alert: data[i]["alert"],
            parameters: data[i]["parameters"]
          });
          if (isNotify) {
            this.alertService.error(data[i]["alert"]["msg"]);
          }
        } else if (data[i]["alert"]["alert_type"] != 'error_code') {
          let obj = { 
            y: data[i]["value"], 
            x: this.convertUTCDateToLocalDate(data[i]["timestamp"]).getTime(), 
            parameters: data[i]["parameters"] 
          }
          sensorData.push(obj);
        }
      }
      return sensorData;
    } else {
      return [];
    }
  }

  convertUTCDateToLocalDate(date) {
    var newDate = new Date(date);
    newDate.setMinutes(newDate.getMinutes() - newDate.getTimezoneOffset());
    return newDate;
  }

  chartInstance(chart: Highcharts.Chart) {
    this.chartInst = chart;
  }
}