import { Component, OnInit, Input } from "@angular/core";
import { WebsocketService } from "src/app/modules/services/websocket.service";
import { isNullOrUndefined } from "util";

@Component({
  selector: "app-pump-chart",
  templateUrl: "./pump-chart.component.html",
  styleUrls: ["./pump-chart.component.scss"]
})
export class PumpChartComponent implements OnInit {
  @Input() graph;
  constructor(private websocketService: WebsocketService) {}

  ngOnInit() {
    this.websocketService.sensorData.subscribe(data => {
      this.updateSocketData(data);
    });
  }

  updateSocketData(data) {
    for (var i = 0; i < this.graph.chart_details.sensor.length; i++) {
      if (!isNullOrUndefined(data[this.graph.chart_details.sensor[i].uuid])) {
        this.graph.chart_details.sensor[i].data =
          data[this.graph.chart_details.sensor[i].uuid][0];
      }
    }
  }
}
