import { Component, OnInit } from "@angular/core";
import { ModalService } from "../../services/modal.service";
import { MaterialTrackingService } from "../../services/material-tracking.service";
import { isNullOrUndefined } from "util";

@Component({
  selector: "app-material",
  templateUrl: "./material.component.html",
  styleUrls: ["./material.component.scss"]
})
export class MaterialComponent implements OnInit {
  devices = [];
  vehicles = [];
  trips = [];
  tripDevices = [];
  dashboardInfo;
  trip;
  constructor(
    private modalService: ModalService,
    private materialTrackingService: MaterialTrackingService
  ) {}

  ngOnInit() {
    this.getDeviceDetails();
    this.getVehicleDetails();
    this.getDashboardDetails();
    this.getTripDetails();
  }

  getDeviceDetails() {
    this.materialTrackingService.getDeviceDetails().subscribe((resp: any) => {
      if (resp.status) {
        this.devices = resp.data;
      }
    });
  }

  getDashboardDetails() {
    this.materialTrackingService
      .getDashboardDetails()
      .subscribe((resp: any) => {
        if (resp.status) {
          this.dashboardInfo = resp.data;
        }
      });
  }

  getVehicleDetails() {
    this.materialTrackingService.getVehicleDetails().subscribe((resp: any) => {
      if (resp.status) {
        this.vehicles = resp.data;
      }
    });
  }

  getTripDetails() {
    this.materialTrackingService.getTripDetails().subscribe((resp: any) => {
      if (resp.status) {
        this.trips = resp.data;
        if (this.trips.length > 0) {
          this.trip = this.trips[0];
          this.onTripChange(this.trips[0]);
        }
      }
    });
  }

  openCreateTripModal() {
    this.modalService.loadDynamicModal({
      componentData: {},
      component: "CreateTripModalComponent"
    });
    var subscription = this.modalService.onModalDismiss.subscribe(data => {
      this.getTripDetails();
      subscription.unsubscribe();
    });
  }

  onTripChange(trip) {
    this.tripDevices = trip.devices;
  }

  openCreateVehicleModal(vehicle) {
    this.modalService.loadDynamicModal({
      componentData: {
        vehicle,
        title: !isNullOrUndefined(vehicle)
          ? "Edit Vehicle Details"
          : "Add Vehicle Details"
      },
      component: "CreateVehicleModalComponent"
    });
    var subscription = this.modalService.onModalDismiss.subscribe(data => {
      this.getVehicleDetails();
      subscription.unsubscribe();
    });
  }

  openCreateDeviceModal(device) {
    this.modalService.loadDynamicModal({
      componentData: {
        device,
        title: !isNullOrUndefined(device)
          ? "Edit Device Details"
          : "Add Device Details"
      },
      component: "CreateDeviceModalComponent"
    });
    var subscription = this.modalService.onModalDismiss.subscribe(data => {
      this.getDeviceDetails();
      subscription.unsubscribe();
    });
  }

  tripAlertsModel() {
    this.modalService.loadDynamicModal({
      componentData: {},
      component: "TripAlertsModalComponent"
    });
    var subscription = this.modalService.onModalDismiss.subscribe(data => {
      this.getDashboardDetails();
      subscription.unsubscribe();
    });
  }
}
