import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ProfileService } from '../../services/profile.service';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  profileForm: FormGroup;
  formData: any;
  user;
  constructor(
    private fb: FormBuilder,
    private profileService: ProfileService,
    private alertService: AlertService
  ) {
    this.user = JSON.parse(localStorage.getItem("user"));
  }

  ngOnInit() {
    this.profileForm = new FormGroup({
      first_name: new FormControl('', [Validators.required]),
      last_name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      contact_number: new FormControl('', [Validators.required, Validators.pattern('[0-9]{10}')]),
      // agreeToTerms: new FormControl(false, [Validators.requiredTrue])
    });
    this.fetchUserProfile();
  }

  onSubmit() {
    if (this.profileForm.valid) {
      const postData = this.profileForm.value
      this.profileService.postProfileData(postData).subscribe((response) => {
        this.alertService.success(response.validation)
      });
    }
  }

  fetchUserProfile() {
    this.profileService.getProfileData().subscribe(
      (response: any) => {
        this.formData = response;
        this.profileForm.setValue({
          first_name: this.formData.data.first_name,
          last_name: this.formData.data.last_name,
          email: this.formData.data.email,
          contact_number: this.formData.data.contact_number,
          // agreeToTerms: true,
        });
      },
      (error) => {
        console.error('Error fetching profile data: ', error);
      }
    );
  }
}
