import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Input
} from "@angular/core";
import { WebsocketService } from "../../../services/websocket.service";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Component({
  selector: "app-company-layout",
  templateUrl: "./company-layout.component.html",
  styleUrls: ["./company-layout.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyLayoutComponent implements OnInit {
  @ViewChild("container", { static: true, read: ElementRef })
  @Input() dept: any;
  container: ElementRef;
  innerHTML: SafeHtml;
  company;
  re = /\${data\["([^\}]+)(?="\]})/g;

  constructor(
    private websocketService: WebsocketService,
    private _cdr: ChangeDetectorRef,
    private _sanitizer: DomSanitizer,
  ) {}

  ngOnInit() {
    this.loadSVGImage(this.dept.layout_svg);
  }

  loadSVGImage(image){
    let { data, svg } = {
      data: this.loadVariables(image),
      svg: image
    };

    this.websocketService.sensorData.subscribe(socketData => {
      this.updateSocketData(socketData, data, svg);
    });

    this._addSvg(data, svg);
  }

  private _addSvg(data, svg: string): void {
    const evalSvg = eval("`" + svg + "`");
    this.innerHTML = this._sanitizer.bypassSecurityTrustHtml(evalSvg);
  }

  loadVariables(svg) {
    let data = {};
    var m;
    while ((m = this.re.exec(svg)) !== null) {
      data[m[1].toString()] = 0;
    }
    return data;
  }

  updateSocketData(socketData, data, svg) {
    for (let key in socketData) {
      data[key] = socketData[key][0]["value"].toFixed(2);
    }
    this._addSvg(data, svg);
    this._cdr.detectChanges();
  }
}
