import { Injectable, EventEmitter } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class ModalService {
  public modalWindowData: EventEmitter<any> = new EventEmitter();
  public onModalDismiss: EventEmitter<any> = new EventEmitter();
  constructor() {}

  public loadDynamicModal(data: any) {
    this.modalWindowData.emit(data);
  }

  triggerClick(modalButton) {
    let el: HTMLElement = modalButton.nativeElement;
    el.click();
    modalButton.nativeElement.remove();
  }

  onCloseClick(data) {
    this.onModalDismiss.emit(data)
  }
}
