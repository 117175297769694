import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable,} from 'rxjs';
import { environment } from "./../../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private http: HttpClient) { }

  postProfileData(formData: any): Observable<any> {
    return this.http.post<any>(`${environment.SERVER_ADDRESS}/c/emp/user_profile/`, formData,);
  }

  public getProfileData(){
    return this.http.get(`${environment.SERVER_ADDRESS}/c/emp/user_profile/`);
  }
}

