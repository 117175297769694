import { Component, OnInit } from "@angular/core";
import { CompanyService } from "./../../services/company.service";
import { SensorService } from "../../services/sensor.service";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

declare var $;

@Component({
  selector: "app-side-nav",
  templateUrl: "./side-nav.component.html",
  styleUrls: ["./side-nav.component.scss"]
})
export class SideNavComponent implements OnInit {
  user;
  departments;
  searchString = "";
  searchStringSubject: Subject<string> = new Subject();
  searchResp = [];
  constructor(
    private companyService: CompanyService
  ) {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.searchStringSubject.pipe(debounceTime(2000), distinctUntilChanged()).subscribe(value => {
      this.companyService.getSearchDetails(value).subscribe((resp: any) => {
        this.searchResp = resp.data;
      })
    });
  }

  ngOnInit() {
    $(document).ready(() => {
      $(".sidebar-menu").tree();
      // this.onLayoutClick();
      // this.onTasksClick();
    });
    this.getDepartments();
  }

  getDepartments() {
    this.companyService.getDepartments().subscribe((resp: any) => {
      if (resp.status) {
        this.departments = resp.data;
        this.onDepartmentClick(this.departments[0])
      }
    });
  }


  onDepartmentClick(dept) {
    var component = "GraphComponent";
    if ([0, 3].includes(dept.layout_type)) {
      component = "GraphComponent";
    } else if (dept.layout_type == 1) {
      component = "ScreenLayoutComponent";
    } else if (dept.layout_type == 2) {
      component = "MaterialComponent";
    }
    this.companyService.loadDynamicComponent({
      componentData: { dept },
      component: component
    });
  }

  onSearchStringChange(evt) {
    console.log(evt)
    this.searchStringSubject.next(evt);
  }


  onTasksClick() {
    this.companyService.loadDynamicComponent({
      componentData: {},
      component: 'TasksComponent'
    });
  }
}
