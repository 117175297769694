import { Injectable, Output, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { environment } from "./../../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class SensorService {
  constructor(private http: HttpClient) { }
  public allChartsEvent: EventEmitter<any> = new EventEmitter();

  getSensorsData(data) {
    return this.http.post(`${environment.SERVER_ADDRESS}/s/get/data/`, data);
  }

  getKwhSensorsData(data) {
    return this.http.post(`${environment.SERVER_ADDRESS}/s/kwh/trend/`, data);
  }
  
  getPercentageData(data) {
    return this.http.post(`${environment.SERVER_ADDRESS}/s/percentage/trend/`, data);
  }

  getGraphs(dept_id) {
    var data = { id: dept_id };
    return this.http.post(
      `${environment.SERVER_ADDRESS}/s/user/graph/list/`,
      data
    );
  }

  showGraphOnFrontPage(data) {
    return this.http.post(
      `${environment.SERVER_ADDRESS}/s/show/graph/`,
      data
    );
  }

  getEquipmentList(data) {
    return this.http.post(
      `${environment.SERVER_ADDRESS}/c/emp/equipment/list/`,
      data
    );
  }

  getEquipmentGraphList(data) {
    return this.http.post(
      `${environment.SERVER_ADDRESS}/c/emp/chart/list/`,
      data
    );
  }

  downloadReport(data) {
    return this.http.post(
      `${environment.SERVER_ADDRESS}/s/get/data/report/`,
      data,
      { responseType: 'text' }
    );
  }
}
