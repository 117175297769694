import { Component, OnInit } from '@angular/core';
import { ModalService } from "../../services/modal.service";

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit {

  constructor(
    private modalService: ModalService,
  ) { }

  ngOnInit() {
  }

  openCreateTaskModal() {
    this.modalService.loadDynamicModal({
      componentData: {},
      component: "CreateTaskModalComponent"
    });
  }

}
