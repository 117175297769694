import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { AlertService } from 'src/app/modules/services/alert.service';
import { ModalService } from 'src/app/modules/services/modal.service';
import { SensorService } from 'src/app/modules/services/sensor.service';

@Component({
  selector: 'app-equipment-chart-listing',
  templateUrl: './equipment-chart-listing.component.html',
  styleUrls: ['./equipment-chart-listing.component.scss']
})
export class EquipmentChartListingComponent implements OnInit, OnChanges {
  @Input() equipment: any;
  @Input() dept: any;
  graphs = [];
  equipGraphs = [];
  sensorsUUID = [];
  dataLimit = 20;
  constructor(
    private sensorService: SensorService,
    private alertService: AlertService,
    private modalService: ModalService
  ) { }

  ngOnInit() {

  }

  ngOnChanges() {
    this.getEquipmentGraphList(this.equipment.id, this.dept.id)
  }


  getSensorsData(data) {
    var todayDate = new Date();
    data["end_date"] = todayDate.getTime();
    todayDate.setDate(todayDate.getDate() - 2);
    data["start_date"] = todayDate.getTime();
    var self = this;
    return new Promise(function (resolve, reject) {
      self.sensorService.getSensorsData(data).subscribe((resp: any) => {
        resolve(resp);
      });
    });
  }


  getEquipmentGraphList(equip_id, dept_id) {
    this.sensorService.getEquipmentGraphList({ id: equip_id, dept_id }).subscribe((resp: any) => {
      this.equipGraphs = resp.data;
      this.equipGraphs.forEach(graph => {
        for (var i = 0; i < graph.chart_details.sensor.length; i++) {
          this.sensorsUUID.push(graph.chart_details.sensor[i].uuid);
        }
      });
      this.getSensorsData({
        codes: this.sensorsUUID,
        limit: this.dataLimit
      }).then(data => {
        this.equipGraphs.forEach(graph => {
          if (
            graph.chart_details.graph_type == 0 //Chart
          ) {
            for (var i = 0; i < graph.chart_details.sensor.length; i++) {
              this.sensorsUUID.push(graph.chart_details.sensor[i].uuid);
              graph.chart_details.sensor[i].data =
                data[graph.chart_details.sensor[i].uuid];
            }
            this.graphs.push(graph);
          } else if (
            graph.chart_details.graph_type == 1 //Pump
          ) {
            for (var i = 0; i < graph.chart_details.sensor.length; i++) {
              this.sensorsUUID.push(graph.chart_details.sensor[i].uuid);
              graph.chart_details.sensor[i].data =
                (data[graph.chart_details.sensor[i].uuid] ? data[graph.chart_details.sensor[i].uuid][0] : { value: 0 });
            }
            this.graphs.unshift(graph);
          }
        });
      });
    })
  }


  showGraphOnFrontPage(id, dept_id) {
    this.sensorService.showGraphOnFrontPage({ id, dept_id }).subscribe((resp: any) => {
      if (resp.status) {
        this.alertService.success(resp.validation);
      }
    });
  }

  historyModel(chart) {
    this.modalService.loadDynamicModal({
      componentData: { chart },
      component: "HistoryModalComponent"
    });
  }
}
