import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { environment } from "./../../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class MaterialTrackingService {
  constructor(private http: HttpClient) {}
  getDeviceDetails() {
    return this.http.get(`${environment.SERVER_ADDRESS}/mt/device/details/`);
  }

  getVehicleDetails() {
    return this.http.get(`${environment.SERVER_ADDRESS}/mt/vehicle/details/`);
  }

  getTripAlertsDetails() {
    return this.http.get(
      `${environment.SERVER_ADDRESS}/mt/trip/alert/details/`
    );
  }

  getTripDetails() {
    return this.http.get(
      `${environment.SERVER_ADDRESS}/mt/trip/details/`
    );
  }

  addDeviceDetails(data) {
    return this.http.post(
      `${environment.SERVER_ADDRESS}/mt/add/device/details/`,
      data
    );
  }

  addVehicleDetails(data) {
    return this.http.post(
      `${environment.SERVER_ADDRESS}/mt/add/vehicle/details/`,
      data
    );
  }
  
  addTripDetails(data) {
    return this.http.post(
      `${environment.SERVER_ADDRESS}/mt/add/trip/details/`,
      data
    );
  }

  markTripAlertAsRead(data) {
    return this.http.post(
      `${environment.SERVER_ADDRESS}/mt/mark-trip-alert-as-read/`,
      data
    );
  }

  getDashboardDetails() {
    return this.http.get(
      `${environment.SERVER_ADDRESS}/mt/dashboard/details/`
    );
  }
}
