import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from "@angular/common/http";
import { finalize, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import { isNullOrUndefined } from "util";
import { Router } from "@angular/router";
import { AlertService } from './alert.service';

@Injectable({
  providedIn: "root"
})
export class HttpAuthInterceptor implements HttpInterceptor {
  loading;
  private totalRequests = 0;
  constructor(private spinner: NgxSpinnerService, private router: Router, private alertService: AlertService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.spinner.show();
    this.totalRequests++;
    request = request.clone({
      setHeaders: {
        "Content-Type": "application/json"
      },
      withCredentials: true
    });
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (!isNullOrUndefined(event.body.is_login) && !event.body.is_login) {
            this.alertService.error(event.body.validation)
            this.router.navigate(["/login"]);
          }
          return event;
        }
      }),
      finalize(() => {
        this.totalRequests--;
        if (this.totalRequests === 0) {
          this.spinner.hide();
        }
      })
    );
  }
}
