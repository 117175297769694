import { Component, OnInit, Input } from "@angular/core";
import { SensorService } from "../../services/sensor.service";
import * as Highcharts from "highcharts";

@Component({
  selector: "app-graph",
  templateUrl: "./graph.component.html",
  styleUrls: ["./graph.component.scss"]
})
export class GraphComponent implements OnInit {
  @Input() dept: any;
  graphs = [];
  allGraphs = [];
  allChartsGraphs = [];
  allChartsPumps = [];
  sensorsUUID = [];
  highcharts = Highcharts;
  dataLimit = 20;
  layoutShow = "data";
  displaySideBarLayout = false;

  constructor(
    private sensorService: SensorService
  ) {
    console.log("================>")
  }

  ngOnInit() {
    this.onBtnGroupClick("data")
  }

  getGraphs(dept_id) {
    console.log("================>")
    this.graphs = [];
    this.allGraphs = [];
    this.sensorsUUID = [];
    this.sensorService.getGraphs(dept_id).subscribe((resp: any) => {
      if (resp.status) {
        this.allGraphs = resp.data;
        this.allGraphs.forEach(graph => {
          if (![5, 6, 7].includes(graph.chart_details.graph_type)) {
            for (var i = 0; i < graph.chart_details.sensor.length; i++) {
              this.sensorsUUID.push(graph.chart_details.sensor[i].uuid);
            }
          }
        });
        var reqBody = {
          codes: this.sensorsUUID,
          limit: this.dataLimit
        }
        this.getSensorsData(reqBody).then(data => {
          this.allGraphs.forEach(graph => {
            if (
              [0, 2, 3, 4].includes(graph.chart_details.graph_type) //Chart
            ) {
              // graph.data = data[graph.chart_details.sensor.uuid];
              for (var i = 0; i < graph.chart_details.sensor.length; i++) {
                graph.chart_details.sensor[i].data =
                  data[graph.chart_details.sensor[i].uuid];
              }
              this.graphs.push(graph);
            } else if (
              graph.chart_details.graph_type == 1 //Pump
            ) {
              for (var i = 0; i < graph.chart_details.sensor.length; i++) {
                graph.chart_details.sensor[i].data =
                  (data[graph.chart_details.sensor[i].uuid] ? data[graph.chart_details.sensor[i].uuid][0] : { value: 0 });
              }
              this.graphs.unshift(graph);
            } else if ([5, 6, 7, 8].includes(graph.chart_details.graph_type)
            ) {
              this.graphs.unshift(graph);
            }
            this.displayLayout();
          });
        });
      }
    });
  }

  displayLayout() {
    this.graphs.forEach(graph => {
      if (graph.chart_details.graph_type == 2) {
        this.displaySideBarLayout = true;
        return 0
      }
    });
  }

  getSensorsData(data) {
    var todayDate = new Date();
    data["end_date"] = todayDate.getTime();
    todayDate.setDate(todayDate.getDate() - 2);
    data["start_date"] = todayDate.getTime();
    var self = this;
    return new Promise(function (resolve, reject) {
      self.sensorService.getSensorsData(data).subscribe((resp: any) => {
        resolve(resp);
      });
    });
  }

  onBtnGroupClick(btnType) {
    this.layoutShow = btnType;
    if (this.layoutShow == "data") {
      this.getGraphs(this.dept.id);
    } else if (this.layoutShow == "allCharts") {
      this.sensorService.allChartsEvent.emit(true);
    }
  }
}
