import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { PagesRoutingModule } from "./pages-routing.module";
import { PagesComponent } from "./pages/pages.component";
import { DynamicComponent } from "./dynamic-component/dynamic.component";
import { LayoutModule } from "../layout/layout.module";
import { GraphComponent } from "./graph/graph.component";
import { ChartComponent } from "./graph/chart/chart.component";
import { HighchartsChartComponent } from "highcharts-angular";
import { MaterialComponent } from "./material/material.component";
import { CompanyLayoutComponent } from "./graph/company-layout/company-layout.component";
import { CreateTripModalComponent } from "./material/create-trip-modal/create-trip-modal.component";
import { CreateDeviceModalComponent } from './material/create-device-modal/create-device-modal.component';
import { CreateVehicleModalComponent } from './material/create-vehicle-modal/create-vehicle-modal.component';
import { TasksComponent } from './tasks/tasks.component';
import { CreateTaskModalComponent } from './tasks/create-task-modal/create-task-modal.component';
import { TripAlertsModalComponent } from './material/trip-alerts-modal/trip-alerts-modal.component';
import { PumpChartComponent } from './graph/pump-chart/pump-chart.component';
import { HistoryModalComponent } from './graph/history-modal/history-modal.component';
import { EquipmentListingComponent } from './graph/equipment-listing/equipment-listing.component';
import { EquipmentChartListingComponent } from './graph/equipment-chart-listing/equipment-chart-listing.component';
import { SidebarGraphComponent } from './graph/sidebar-graph/sidebar-graph.component';
import { SolidGaugeChartComponent } from './graph/solid-gauge-chart/solid-gauge-chart.component';
import { ScreenLayoutComponent } from './screen-layout/screen-layout.component';
import { PiChartComponent } from './graph/pi-chart/pi-chart.component';
import { TableComponent } from './graph/table/table.component';
import { ProfileComponent } from "./profile/profile.component";
import { FftComponent } from './graph/fft/fft.component';
import { KwhTrendComponent } from './graph/kwh-trend/kwh-trend.component';
import { PercentageBarChartComponent } from './graph/percentage-bar-chart/percentage-bar-chart.component';

@NgModule({
  declarations: [
    PagesComponent,
    DynamicComponent,
    GraphComponent,
    ChartComponent,
    HighchartsChartComponent,
    MaterialComponent,
    CompanyLayoutComponent,
    CreateTripModalComponent,
    CreateDeviceModalComponent,
    CreateVehicleModalComponent,
    TasksComponent,
    CreateTaskModalComponent,
    TripAlertsModalComponent,
    PumpChartComponent,
    HistoryModalComponent,
    EquipmentListingComponent,
    EquipmentChartListingComponent,
    SidebarGraphComponent,
    SolidGaugeChartComponent,
    ScreenLayoutComponent,
    PiChartComponent,
    TableComponent,
    ProfileComponent,
    FftComponent,
    KwhTrendComponent,
    PercentageBarChartComponent
  ],
  imports: [CommonModule, PagesRoutingModule, LayoutModule, FormsModule, ReactiveFormsModule],
  entryComponents: [
    GraphComponent,
    MaterialComponent,
    ScreenLayoutComponent,
    CompanyLayoutComponent,
    CreateTripModalComponent,
    CreateVehicleModalComponent,
    CreateDeviceModalComponent,
    TasksComponent,
    CreateTaskModalComponent,
    TripAlertsModalComponent,
    HistoryModalComponent
  ]
})
export class PagesModule {}
