import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";

import { environment } from "./../../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class WebsocketService {
  socket;
  private sensorDataSource = new BehaviorSubject({});
  sensorData = this.sensorDataSource.asObservable();
  private chatDataSource = new BehaviorSubject({});
  chatData = this.chatDataSource.asObservable();
  constructor(private http: HttpClient) {}

  isEmptyObj(obj) {
    return Object.keys(obj).length === 0;
  }
  changeData(data) {
    if (data["sensor_data"] && !this.isEmptyObj(data["sensor_data"])) {
      this.sensorDataSource.next(data["sensor_data"]);
    } else if (data["chat_data"] && !this.isEmptyObj(data["chat_data"])) {
      this.chatDataSource.next(data["chat_data"]);
    }
  }

}
