import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from "highcharts";
import { SensorService } from 'src/app/modules/services/sensor.service';
import { isNullOrUndefined } from "util";

@Component({
  selector: 'app-percentage-bar-chart',
  templateUrl: './percentage-bar-chart.component.html',
  styleUrls: ['./percentage-bar-chart.component.scss']
})
export class PercentageBarChartComponent implements OnInit {
  @Input() chart: any;
  chartInst;
  highcharts = Highcharts;
  chartOptions;
  endDate = new Date();
  startDate = new Date();
  dataObj = {
    title: "",
    subtitle: "",
    yAxisTitle: ""
  };
  chartData = {};
  loadChart = false;
  constructor(private sensorService: SensorService) {
    var todayDate = new Date();
    todayDate.setDate(todayDate.getDate() - 10);
    this.startDate = todayDate;
  }

  ngOnInit() {
    console.log(this.chart);
    this.chartOptions = this.getGraphData();
    this.getSensorsData();
  }

  getSensorsData() {
    var data = {
      codes: [],
      limit: 20000
    }
    for (var i = 0; i < this.chart.chart_details.sensor.length; i++) {
      data.codes.push(this.chart.chart_details.sensor[i].uuid)
    }
    data["end_date"] = new Date(this.endDate).getTime();
    data["start_date"] = new Date(this.startDate).getTime();
    this.sensorService.getPercentageData(data).subscribe((resp: any) => {
      const [sensorDataShiftA, sensorDataShiftB, sensorDataShiftC] = this.getGraphDataArray(resp)

      this.chartOptions.series.push({
        name: "Shift A",
        data: sensorDataShiftA,
        turboThreshold: 0
      });
      this.chartOptions.series.push({
        name: "Shift B",
        data: sensorDataShiftB,
        turboThreshold: 0
      });
      this.chartOptions.series.push({
        name: "Shift C",
        data: sensorDataShiftC,
        turboThreshold: 0
      });
      console.log(this.chartOptions.series);
      var self = this;
      setTimeout(() => {
        self.loadChart = true;
      }, 1000);
      console.log(this.chartOptions)
    });
  }

  getGraphData() {
    for (var i = 0; i < this.chart.chart_details.sensor.length; i++) {
      if (!isNullOrUndefined(this.chart.chart_details.sensor[i]['equipment'])) {
        this.dataObj['title'] = this.chart.chart_details.sensor[i]['equipment']['name']
      } else {
        this.dataObj['title'] = this.chart.chart_details.sensor[i]['department']['name']
      }
      this.dataObj['subtitle'] = this.chart.chart_details.sensor[i]['measured_parameter_type_disp']
      this.dataObj['yAxisTitle'] = this.chart.chart_details.sensor[i]['unit_disp']
      // this.chartData[this.chart.chart_details.sensor[i].uuid] = this.getGraphDataArray(this.chart.chart_details.sensor[i]['data']);
      this.dataObj[this.chart.chart_details.sensor[i].uuid] = "this.chartData[this.chart.chart_details.sensor[" + i + "].uuid]"
    }

    var chart = eval("(" + this.chart.chart_details.javascript.replace(/[^\x20-\x7E]/gim, "") + ")")
    return chart
  }

  getGraphDataArray(data) {
    var sensorDataShiftA = [];
    var sensorDataShiftB = [];
    var sensorDataShiftC = [];
    if (!isNullOrUndefined(data)) {
      for (var i = data.length; i--;) {

        var val = parseFloat(data[i]["percentage"].toFixed(2));
        let obj = {
          y: val,
          x: this.convertUTCDateToLocalDate(data[i]["timestamp"]).getTime(),
        }
        if (data[i]["time_period"] == "shift_a") {
          sensorDataShiftA.push(obj);
        }
        if (data[i]["time_period"] == "shift_b") {
          sensorDataShiftB.push(obj);
        }
        if (data[i]["time_period"] == "shift_c") {
          sensorDataShiftC.push(obj);
        }
      }
      return [sensorDataShiftA, sensorDataShiftB, sensorDataShiftC];
    } else {
      return [[], [], []];
    }
  }

  chartInstance(chart: Highcharts.Chart) {
    this.chartInst = chart;
  }
  convertUTCDateToLocalDate(date) {
    var newDate = new Date(date);
    newDate.setMinutes(newDate.getMinutes() - newDate.getTimezoneOffset());
    return newDate;
  }

  showData() {
    this.loadChart = false;
    this.chartOptions.series.pop();
    this.chartOptions.series.pop();
    this.chartOptions.series.pop();
    this.getSensorsData();
  }
}
