import { Component, OnInit } from "@angular/core";
import { WebsocketService } from "../../services/websocket.service";
import { AuthService } from "../../services/auth.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {
  user;
  constructor(
    private websocketService: WebsocketService,
    private authService: AuthService
  ) {
    this.user = JSON.parse(localStorage.getItem("user"));
  }

  ngOnInit() {
    this.websocket();
  }
  websocket() {
    var self = this;
    var socket = new WebSocket(
      "wss://"+location.hostname+":1994?session_key=" + self.user.sessionid
    );
    socket.onmessage = function(e) {
      var data = JSON.parse(e.data);
      self.websocketService.changeData(data);
    };
    socket.onopen = function() {
      // setInterval(() => {
      //   socket.send(
      //     JSON.stringify({
      //       chat_data: {
      //         user: "Alexander Pierce",
      //         datetime: "23 Jan 2:00 pm",
      //         profile_pic: "assets/dist/img/user2-160x160.jpg",
      //         text: "Is this template really for free? That's unbelievable!",
      //         align: "left",
      //         username: "nikhilrane"
      //       }
      //     })
      //   );
      // }, 3000);
      self.websocketService.socket = socket;
    };
    // Call onopen directly if socket is already open
    if (socket.readyState == WebSocket.OPEN) {
      socket.onopen(event);
    }
  }

  logout() {
    this.authService.logout().subscribe((resp: any) => {
    });
  }

  triggerResize(){
    window.dispatchEvent(new Event('resize'));
  }

}
