import { Component, Input, OnInit } from '@angular/core';

import * as Highcharts from "highcharts";
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-pi-chart',
  templateUrl: './pi-chart.component.html',
  styleUrls: ['./pi-chart.component.scss']
})
export class PiChartComponent implements OnInit {
  @Input() chart: any;
  @Input() data: any;
  highcharts = Highcharts;
  chartOptions;
  chartInst;
  updateFlag = true;
  dataObj = {
    title: "",
    subtitle: "",
    yAxisTitle: ""
  };
  chartData = {};

  constructor() { }

  ngOnInit() {
    this.dataObj["title"] = this.chart.chart_details.name;
    this.chartOptions = this.getGraphData(this.chart);
    this.chartOptions = Object.assign(this.chartOptionsParameter(), this.chartOptions)
    var chartSeriseData = []
    for (var i = 0; i < this.chart.chart_details.sensor.length; i++) {
      chartSeriseData.push({
        uuid: this.chart.chart_details.sensor[i].uuid,
        name: this.chart.chart_details.sensor[i].name,
        y: !isNullOrUndefined(this.chartData[this.chart.chart_details.sensor[i].uuid][0]) ? this.chartData[this.chart.chart_details.sensor[i].uuid][0]['y']: 0
      });
    }
    this.chartOptions.series.push({
      name: this.chart.chart_details.name,
      colorByPoint: true,
      data: chartSeriseData
    })
  }

  chartOptionsParameter() {
    return {
      title: { text: this.dataObj['title'] },
      subtitle: { text: this.dataObj['subtitle'] },
      credits: {
        enabled: false
      },
      chart: {
        zoomType: 'x'
      }
    }
  }

  getGraphDataArray(data) {
    var sensorData = [];
    if (!isNullOrUndefined(data)) {
      for (var i = 0; i < data.length; i++) {
        let obj = {
          y: data[i]["value"]
        }
        sensorData.push(obj);
      }
      return sensorData;
    } else {
      return [];
    }
  }

  convertUTCDateToLocalDate(date) {
    var newDate = new Date(date);
    newDate.setMinutes(newDate.getMinutes() - newDate.getTimezoneOffset());
    return newDate;
  }


  getGraphData(graph) {
    for (var i = 0; i < this.chart.chart_details.sensor.length; i++) {
      this.chartData[graph.chart_details.sensor[i].uuid] = this.getGraphDataArray(graph.chart_details.sensor[i]['data']);
    }

    var optionString = graph.chart_details.javascript.replace(
      /\{(\w+)\}/g,
      function (_, k) {
        return this.dataObj[k];
      }
    );
    var chart = eval("(" + optionString.replace(/[^\x20-\x7E]/gim, "") + ")")
    return chart
  }

  chartInstance(chart: Highcharts.Chart) {
    this.chartInst = chart;
  }

}
