import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { environment } from "./../../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class CompanyService {
  public dashboardComponentData: EventEmitter<any> = new EventEmitter();
  constructor(private http: HttpClient) {}

  getDepartments() {
    return this.http.get(
      `${environment.SERVER_ADDRESS}/c/emp/department/list/`
    );
  }

  getCompanyDetails() {
    return this.http.get(
      `${environment.SERVER_ADDRESS}/c/emp/company/details/`
    );
  }
  
  getPlantDetails() {
    return this.http.get(
      `${environment.SERVER_ADDRESS}/c/emp/plant/details/`
    );
  }
  
  getUsersDetails() {
    return this.http.get(
      `${environment.SERVER_ADDRESS}/c/emp/user/details/`
    );
  }
  
  getSearchDetails(searchString) {
    return this.http.get(
      `${environment.SERVER_ADDRESS}/c/emp/search/?search_string=`+searchString
    );
  }
  
  public loadDynamicComponent(data: any) {
    this.dashboardComponentData.emit(data);
  }
}
